import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { API_URL } from '../config';

export default function ParametriPTSD({ idAtleta, idCombinazione }) {
    const { t } = useTranslation();

    useEffect(() => {
        callAPI();
        //attualmente hanno valori statici perché non ho idea di come fare le percentuali <3
    }, [idAtleta, idCombinazione]);

    //
    //totale dei taskId dell'utente
    const [Totalecombinazioni, setTotaleCombinazioni] = useState([]);

    //valore minore tra i max(firstTime) dell'utente
    const [BestPerformance, setBestPerformance] = useState([]);

    //tempo medio di esecuzione confrontando i max(firstTime) dell'utente group by sessionId
    const [AveragePerformance, setAveragePerformance] = useState([]);
    
    //valori delle varie intensità calcolate lato server con valori 0.9 ed 0.8
    // N. RIPETIZIONI Ad intensità  al 90%
    const [Intensita90, setIntensita90] = useState([]);
    //N. RIPETIZIONI Ad intensità  al 80%-90%
    const [Intensita80_90, setIntensita80_90] = useState([]);
    // N. RIPETIZIONI Ad intensità  al 80%
    const [Intensita80, setIntensita80] = useState([]);


    const callAPI = async () => {
        const response = await fetch(`${API_URL}/api/infoPerformance`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                schemaId:  idCombinazione,
                idAtleta:  idAtleta
            }),
        })

        const data = await response.json()
        setTotaleCombinazioni(data.totTaskId);

        setBestPerformance(data.bestPerformance);

        setAveragePerformance(data.averagePerformance);

        
        setIntensita90(data.intensita90);
        setIntensita80_90(data.intensita90_80);
        setIntensita80(data.intensita80);
    }




    return (
        <div>

            <div className="row ">
                <div className="col-xs-4 col-sm-4 pad-L0">
                    <div className="contMid-1">
                        <div className="circleDati">
                            <span>{Totalecombinazioni.length}</span>
                        </div>
                    </div>
                    <div className="contMid-2">
                        <h5>{t('nCombinazioni')} <br /> {t('eseguite')}</h5>
                    </div>
                </div>
                <div className="col-xs-4 col-sm-4 pad-L0">
                    <div className="contMid-1">
                        <div className="circleDati">
                            <span id="BestPerformance">{BestPerformance}</span>
                        </div>
                    </div>
                    <div className="contMid-2">
                        <h5> {t('best_performance')} <br /> {t('questa_comb')}</h5>
                    </div>
                </div>
                <div className="col-xs-4 col-sm-4 pad-L0">
                    <div className="contMid-1">
                        <div className="circleDati">
                            <span>{AveragePerformance}</span>
                        </div>
                    </div>
                    <div className="contMid-2">
                        <h5> {t('average_performance')} <br /> {t('questa_comb')}</h5>
                    </div>
                </div>
            </div>

            <div className="row brdBot">
                <div className="col-xs-4 col-sm-4 pad-L0">
                    <div className="contMid-1">
                        <div className="circleDati">
                            <span>{Intensita90}</span>
                        </div>
                    </div>
                    <div className="contMid-2">
                        <h5> {t('nRipetizioni')} <br /> {t('intensita_90')} </h5>
                    </div>
                </div>
                <div className="col-xs-4 col-sm-4 pad-L0">
                    <div className="contMid-1">
                        <div className="circleDati">
                            <span>{Intensita80_90}</span>
                        </div>
                    </div>
                    <div className="contMid-2">
                        <h5> {t('nRipetizioni')} <br /> {t('intensita_8090')} </h5>
                    </div>
                </div>
                <div className="col-xs-4 col-sm-4 pad-L0">
                    <div className="contMid-1">
                        <div className="circleDati">
                            <span>{Intensita80}</span>
                        </div>
                    </div>
                    <div className="contMid-2">
                        <h5> {t('nRipetizioni')} <br /> {t('intensita_80')} </h5>
                    </div>
                </div>
            </div>
        </div>
    );
}