import React, { useState } from "react";
import logo from './assets/img/logo-pts.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
//import jwt from 'jwt-decode'
import { API_URL } from './config';

function Login() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    async function callAPILogin(event) {
        //event.preventDefault()
        const response = await fetch(`${API_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
            }),
        })

        const data = await response.json()

        if(data.user){
            localStorage.setItem('token', data.user)
            alert('Login effettuato')
            navigate('/dashboard')
        } else {
            alert('Controlla gli input')
        }
        console.log(data);
    }

    return (
        <div className="row row-height-safari">
            <div id="pagina" className="bgRight login">
                <div className="contLogoLogin">
                    <img className="logoPTS" src={logo} alt="" />
                </div>
                <h1>{t('AREA RISERVATA')}</h1>
                <form class="loginForm">
                    <span for="first">{t('username')}</span>
                    <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" id="username" name="username" />
                    <span for="last">{t('password')}</span>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="text" id="password" name="password" />
                    <button type="button" onClick={callAPILogin} >{t('accedi')}</button>
                </form>
            </div>
        </div>
    )
}

export default Login
