import React, { useEffect } from "react";
import jwt from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import Sidebar from './components/sidebar'

function Dashboard() {
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user = token ? jwt(token) : null;

        if (!user) {
            localStorage.removeItem('token');
            navigate('/login');
        }
    }, [])

    return (
        <div className="row " >
            <Sidebar />
            <div id="pagina" className="col-xs bgRight pad-T2 pad-L2 pad-R2 row-height row-height-safari">
                <h1> {t('dashboard')}</h1>
                <div className="miniUnder"></div>
            </div>
        </div>
    )
}

export default Dashboard