import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import './styles/globals.css'
import './styles/base.css'
import './styles/font.css'
import './styles/flexboxgrid.css'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'it'],
    fallbackLng:'it',
    backend: {
      loadPath: '/assets/locales/{{lng}}.json',
    },
    react: { useSuspence: false },
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
    },
  })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
