import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { API_URL } from '../config';

export default function Anagrafica({ selectedAtleta }) {
    const { t } = useTranslation()
    const [anagrafica, setAnagrafica] = useState([]);



    useEffect(() => {
        if (selectedAtleta === -1) {
            setAnagrafica(0);
            return;
        }

        const callAPIAnagrafica = async () => {
            try {
                const response = await fetch(`${API_URL}/api/anagraficaAtleta`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        idAtleta: selectedAtleta
                    }),
                })

                const data = await response.json()
                setAnagrafica(data.atleta);
            }
            catch (err) {
                console.log(err);
            }
        };

        callAPIAnagrafica();
    }, [selectedAtleta])

    return (
        <>
            <div className="col-xs-4 col-sm-4">
                {
                    Array.isArray(anagrafica) && anagrafica.map((elemento, id) => {
                        return (
                            <div className="boxInfo" key={id}>
                                <h4>{t('anagrafica')}</h4>
                                <p><h5>{t('nome')}</h5><span className="dato">{elemento.name}</span></p>
                                <p><h5>{t('cognome')}</h5> <span className="dato">{elemento.surname}</span></p>
                                <p><h5>{t('natoIl')}</h5> <span className="dato">{elemento.birth}</span></p>
                            </div>
                        );
                    })
                }
            </div>

            <div className="col-xs-4 col-sm-4">
                {
                    Array.isArray(anagrafica) && anagrafica.map((elemento, id) => {
                        return (
                            <div className="boxInfo">
                                <h4>{t('dati_fisici')}</h4>
                                <p><h5>{t('altezza')}</h5> <span className="dato">{elemento.altezza} m</span></p>
                                <p><h5>{t('peso')}</h5> <span className="dato">{elemento.peso} Kg</span></p>
                                <p><h5>{t('bmi')}</h5> <span className="dato">{elemento.BMI}</span></p>
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
}
