import React from "react";
import { useTranslation } from 'react-i18next';

function Ancore() {
//<span className="breadcrumbs" >STATISTICHE </span> <span className="breadcrumbs">| </span> <span className="breadcrumbs">COMPARAZIONE </span>
    const { t } = useTranslation();

    return(
        <div className="row">
            <div className="col-xs-12 col-sm-12 pad-L0">   
                <h2>{t('scheda_atleta')}</h2>
                <div className="miniUnder"></div>
            </div>
        </div>
    );
}

export default Ancore

