import React, { useState, useEffect } from 'react';
import { VictoryChart, VictoryAxis, VictoryArea, VictoryBar } from 'victory';
import { useTranslation } from "react-i18next";
import { API_URL } from '../config';

export default function CalcolaPTSD({ idAtleta, setIdCombinazione }) {
    const { t } = useTranslation();

    const [idSchema, setIdSchema] = useState(-1);
    const [idSequence, setIdSequence] = useState(-1);

    const [sequence, setSequence] = useState([]);
    const [schemi, setSchemi] = useState([]);
    const [tempo, setTempo] = useState([]);
    const [TMEcombinazioni, setTMECombinazioni] = useState([]);
    const [tempoSchemaUtente, setTempoSchemaUtente] = useState([]);
    const [GraficoSequenza, setGraficoSequenza] = useState([]);

    const callAPIallSequence = async () => {
        try {
            const response = await fetch(`${API_URL}/api/sequence`, {
                method: 'POST',
            })

            const data = await response.json();

            // Ordina l'array in base al valore della chiave "name"
            data.sequence.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            });

            setSequence(data.sequence);
            console.log(data.sequence);
        }
        catch (err) {
            console.log(err);
        }
    };

    const changeSequence = async (idSequenza) => {
        try {
            const response = await fetch(`${API_URL}/api/tempoSequenza`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idAtleta: idAtleta
                }),
            })

            const data = await response.json()
            setIdSequence(idSequenza);
            setCombinazione(0);
            setTempo(data.velocita);
            callAPIschemi(idSequenza);
        }
        catch (err) {
            console.log(err);
        }
    }

    const callAPIschemi = async (idSequenza) => {
      try {
        const response = await fetch(`${API_URL}/api/schemi`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idSequenza: idSequenza,
            idAtleta: idAtleta,
          }),
        });

        const data = await response.json();

        // Ordina l'array in base al valore della chiave "name"
        data.schemi.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        setSchemi(data.schemi);
        //setIdSchemi(data.idSchemi);
        setGraficoSequenza(data.datiGrafico);
        console.log(GraficoSequenza);
      } catch (err) {
        console.log(err);
      }
    };

    function setCombinazione(idSchema) {
        setIdSchema(idSchema);
        setIdCombinazione(idSchema);
        callAPITMECombinazioni(idSchema);

    }

    const callAPITMECombinazioni = async (idSchema) => {
        try {
            const response = await fetch(`${API_URL}/api/tmeSchema`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idSchema: idSchema,
                    idAtleta: idAtleta
                }),
            })

            const data = await response.json()
            setTMECombinazioni(data.tme);
            setTempoSchemaUtente(data.minMaxFirstTime);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        callAPIallSequence();
        changeSequence(0);
    }, [idAtleta]);



    return (
        <div className="mrgT2">
            <div className="row ">
                <div className="col-xs-12 col-sm-12 pad-L0">
                    <h2>{t('calcola_ptsd')}</h2>
                    <span>
                        <h5 className="long">{t('velocita_30m')}</h5>
                        <span className="dato2">{tempo}'' </span>
                    </span>
                    <br />
                    <br />
                    <label className='width-label-10' >{t('sequenza')}</label>
                    <select name="sequenza" id="sequenza" className="small" value={idSequence} onChange={(event) => changeSequence(event.target.value)}>
                        <option value="0" key="0">{t('nessuna')}</option>
                        {

                            sequence.map((elemento, id) => {
                                return (
                                    <option value={elemento.id} key={id}>{elemento.name}</option>
                                );
                            })

                        }
                    </select>
                    <div >

                        {/*VictoryChart lo togli se non vuoi i numerini sotto */}
                        <VictoryChart style={{ background: { fill: "#555555" } }} domain={{ y: [0, tempo] }} width={1000} height={200}>
                            <VictoryAxis dependentAxis domain={[0, tempo]} standalone={false} />
                            <linearGradient id="myGradient" gradientTransform="rotate(90)">

                                <stop offset="10%" stopColor="#b83e19" />
                                <stop offset="100%" stopColor="#0e1e37" />
                            </linearGradient>
                            <VictoryArea interpolation="natural"
                                style={{ data: { fill: "url(#myGradient)" } }}
                                width={1000}
                                height={50}
                                data={GraficoSequenza}
                            />
                        </VictoryChart>
                    </div>

                    <div className='row align-items-baseline'>
                        <label className='width-label-10' >{t('combinazione')}</label>
                        <select name="combinazione" id="combinazione" className="small" value={idSchema} onChange={(event) => setCombinazione(event.target.value)}>
                            <option value="0" key="0">{t('nessuna')}</option>
                            {

                                schemi.map((elemento, id) => {
                                    return (
                                        <option value={elemento.id} key={id}>{elemento.name}</option>
                                    );
                                })

                            }
                        </select>

                        <label className='mx-1'>{t('combinazioni_nascoste')}</label>
                    </div>

                    <div > {/*className="contGrafico"*/}
                        <VictoryChart domain={{ y: [0, tempo + 5] }} style={{ background: { fill: "#555555" } }} width={1000} height={200}>
                            <linearGradient id="myGradient2" gradientTransform="rotate(90)">
                                <stop offset="10%" stopColor="#b83e19" />
                                <stop offset="100%" stopColor="#0e1e37" />
                            </linearGradient>
                            <VictoryAxis tickValues={[1, 2, 3, 4]} style={{ tickLabels: { fill: "none" } }} />
                            <VictoryAxis dependentAxis width={400} height={400} domain={[0, tempo + 5]} standalone={false} />
                            <VictoryBar interpolation="natural"
                                style={{
                                    data: { fill: "url(#myGradient2)" }
                                }}

                                data={[
                                    { x: 2, y: tempo },
                                    { x: 3, y: tempoSchemaUtente }
                                ]}
                            />
                        </VictoryChart>
                    </div>
                </div>
            </div>
        </div>
    );
}
